import { ThemeProvider } from '@bob/library'
import { ApolloProvider } from '@websrc/App/ApolloProvider'
import { GatsbyBrowser } from 'gatsby'
import { themeClass } from 'leds'
import React from 'react'

// Shared component for Gatsby to wrap each page on both server side rendering and browser
export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({ element }) => (
  <div className={themeClass}>
    <ThemeProvider>
      <ApolloProvider>{element}</ApolloProvider>
    </ThemeProvider>
  </div>
)
