import { ApolloClient, ApolloProvider as Provider, HttpLink, InMemoryCache } from '@apollo/client'
import { isServer } from '@bob/library'
import fetch from 'cross-fetch'
import React, { FC, PropsWithChildren } from 'react'

const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  ssrMode: isServer,
  link: new HttpLink({
    uri: '/api/v1/graphql',
    fetch,
  }),
})

export const ApolloProvider: FC<PropsWithChildren> = ({ children }) => (
  <Provider client={apolloClient}>{children}</Provider>
)
